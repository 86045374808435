const jQuery = require('jquery');
window.$ = window.jQuery = jQuery;

import fitty from 'fitty/dist/fitty.min.js';
import { tns } from 'tiny-slider/src/tiny-slider';
import Froffcanvas from 'fr-offcanvas';

var Stickyfill = require('stickyfill');

// you can optionally pass `document` and `window` for reuse in iframes
var stickyfill = Stickyfill();


var elements = $('.page-head');
// make sure to add the elements you want to polyfill
stickyfill.add(elements);

// fitty('.story__title', {
//     minSize: 35,
//     maxSize: 100,
//     multiLine: true
// });

// Tabs
$(document).ready(function(){ 
  $('.tab-a').click(function(){  
    $(".tab").removeClass('tab-active');
    $(".tab[data-id='"+$(this).attr('data-id')+"']").addClass("tab-active");
    $(".tab-a").removeClass('active-a');
    $(this).parent().find(".tab-a").addClass('active-a');
   });
});

// Sidebar
$("#sidebar").on('click','.sidebar__li',function(){
    // remove classname 'active' from all li who already has classname 'active'
    $("#sidebar .sidebar__li.active").removeClass("active"); 
    // adding classname 'active' to current click li 
    $(this).addClass("active"); 
});

(function() {
    'use strict';
  
    var section = document.querySelectorAll(".bodybuilder");
    var sections = {};
    var i = 0;
  
    Array.prototype.forEach.call(section, function(e) {
      sections[e.id] = e.offsetTop;
    });
  
    window.onscroll = function() {
      var scrollPosition = document.documentElement.scrollTop || document.body.scrollTop;
  
      for (i in sections) {
        if (sections[i] <= scrollPosition) {
          document.querySelector('.active').setAttribute('class', ' ');
          document.querySelector('a[href*=' + i + ']').setAttribute('class', 'active');
        }
      }
    };
  })();

// Sidebar
// Scroll to id 
  // Select all »a« elements with a parent class »links« and add a function that is executed on click
  $(document).ready(function(){
      // Add smooth scrolling to all links
      $("a").on('click', function(event) {
        
        if (this.hash !== "") {
          
          // Store hash
          var hash = this.hash;
          // Run a scroll animation to the position of the element which has the same id like the href value.
          $('html, body').animate({scrollTop: $(hash).offset().top -140 }, '500');

        } // End if
    });
  });

  
  $("#language").change(function() {
      var op =$(this).val();
      if(op !='') {                 
          $('input[name="searchstudybtn"]').prop('disabled',false);
      } else {
          $('input[name="searchstudybtn"]').prop('disabled', true);
      }   
  });

  $(document).ready(function(){
      // bind change event to select
        $("#searchstudybtn").click(function(){
        // $('#dynamic_select').on('change', function () {
          var learnOption = $('#learn-option').find(":selected").val();
          var language = $('#language').find(":selected").val();

          var url = language + learnOption;
          if (url) { // require a URL
              window.location = url; // redirect
          }
          return false;

      });
    });

  // var urlParams = new URLSearchParams(window.location.search);
  // let queryString = urlParams.get('selectedProduct');
  
  // // Find the option in the select that has the same value as
  // // the query string value and make it be selected
  // document.getElementById("myProduct").querySelector("option[value='" + queryString + "']").selected = true;
  
if($(".js-slider").length) {

    var slider = tns({
        container: '.js-slider',
        items: 1,
        autoWidth: true,
        gutter: 20,
        controlsContainer: '#custom-controls',
        responsive: {
            640: {
                edgePadding: 20,
                gutter: 20,
                items: 2
            },
            700: {
                gutter: 30
            },
            900: {
                items: 6
            }
        }
    });
}


var myOffcanvas = Froffcanvas();